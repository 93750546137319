import { HistoryObserver, HistoryState, HistorySubject, IHistoryModel } from "./HistoryInterface";

export default class HistoryEventManager implements HistorySubject {
    private observers: Array<[HistoryState, HistoryObserver]> = new Array;

    attach(historyEventType: HistoryState, observer: HistoryObserver): void {
        const isExist = this.observers.includes([historyEventType, observer]);
        if (isExist)
            return console.log('Observer has already been attached');

        this.observers.push([historyEventType, observer]);
    }

    detach(historyEventType: HistoryState, observer: HistoryObserver): void {
        const observerIndex = this.observers.indexOf([historyEventType, observer]);
        if (observerIndex === -1)
            return console.log('nonexistent observer');

        this.observers.splice(observerIndex, 1);
    }

    notify(data: IHistoryModel): void {
        for (let obs of this.observers.filter(x => x[0] === data.historyState)) {
            obs[1].historyUpdate(data);
        }
    }

}