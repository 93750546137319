import _ from "underscore";
import ItemPicturesV2 from "../../Base/BaseItemPictures";
import { HistoryState } from "../../History/HistoryInterface";
import HistorySingleton from "../../History/HistorySingleton";
import MylnanHistoryModel from "../../Models/MylnanHistoryModel";
import { ItemPictureMain } from "./ItemPicturesMain";

export class Paging {
    $_shopPagingHolder: JQuery;
    $_shopPagingPrevious: JQuery;
    $_shopPagingNext: JQuery;
    $_shopPagingNumbers: JQuery;

    _shopPagingHolder: HTMLElement;
    _shopPagingPrevious: HTMLElement;
    _shopPagingNext: HTMLElement;
    _shopPagingNumbers: HTMLElement;

    _pageSize: number;
    _totalNumberOfItems: number;
    _numberOfPages: number;
    _currenPage: number;
    _displayStart: number;
    _numberOfItemsOnPage: number;

    _itemPicture: ItemPicturesV2;
    private _history: HistorySingleton;

    constructor(pageSize: number, displayStart: number, totalNumberOfItems: number, itemPicture: ItemPicturesV2) {
        this._history = HistorySingleton.getInstance();
        this._itemPicture = itemPicture;
        this._displayStart = displayStart+1;
        this._pageSize = pageSize;
        this._totalNumberOfItems = totalNumberOfItems;
        this._currenPage = 1;
        this._numberOfPages = Math.ceil(this._totalNumberOfItems / this._pageSize);
        this._numberOfItemsOnPage = Math.ceil(this._totalNumberOfItems / this._numberOfPages);

        if (displayStart !== 0) {
            this._currenPage = Math.ceil(this._displayStart / this._numberOfItemsOnPage);
        } 

        this.$_shopPagingHolder = $("#ShopPagingId");
        this.$_shopPagingPrevious = $("#ShopPagingPreviousId");
        this.$_shopPagingNext = $("#PagingNextId");
        this.$_shopPagingNumbers = $("#PagingNumbersId");

        this._shopPagingHolder = document.getElementById("ShopPagingId");
        this._shopPagingPrevious = document.getElementById("ShopPagingPreviousId");
        this._shopPagingNext = document.getElementById("PagingNextId");
        this._shopPagingNumbers = document.getElementById("PagingNumbersId");

        this.Initialize();
    }

    public AddItemPictureMain(main: ItemPictureMain) {
        this.Initialize();
    }

    public ChangeCategory(totalNumberOfItems: number, currentPage: number = 0) {
        this._totalNumberOfItems = totalNumberOfItems;
        this._numberOfPages = Math.ceil(totalNumberOfItems / this._pageSize);
        this._numberOfItemsOnPage = Math.ceil(this._totalNumberOfItems / this._numberOfPages);

        if (this._numberOfPages <= 1) {
            this.$_shopPagingHolder.hide();
            return;
        } else {
            this._currenPage = currentPage;
            this.$_shopPagingHolder.show();
        }

        if (this._numberOfPages > this._currenPage)
            this.$_shopPagingNext.css('visibility', 'visible');
        else
            this.$_shopPagingNext.css('visibility', 'hidden');

        if (this._currenPage === 1)
            this.$_shopPagingPrevious.css('visibility', 'hidden');
        else
            this.$_shopPagingPrevious.css('visibility', 'visible');

        this.$_shopPagingNumbers.empty();
        this.AddNumbers();
        this.AddNumbersListener();
    }

    private Initialize() {
        if (this._numberOfPages > 1) {
            this.$_shopPagingHolder.show();
        };

        this.AddNext();
        this.AddPrevious();
        this.AddNumbers();
        this.AddNumbersListener();
    }

    private PushState(currentPage: number, previousPage: number) {
        let path = decodeURI(window.location.pathname).split('/');

        let historyModel = new MylnanHistoryModel();
        let tempDisplayStart = (currentPage-1) * this._numberOfItemsOnPage;
        historyModel.currentPage = currentPage;
        historyModel.displayStart = tempDisplayStart;
        historyModel.categoryCode = this._itemPicture._categoryCode;
        historyModel.historyState = HistoryState.Paging;

        path.pop();
        path.push(tempDisplayStart.toString());

        this._history.PushState(path.join('/'), historyModel);
    }

    private AddNumbersListener() {
        this.$_shopPagingNumbers.children('a').click((item) => {
            let current = item.currentTarget;
            let previousePage = this._currenPage;

            this._currenPage = Number.parseInt(current.textContent);
            this._itemPicture.HandlePaging((this._pageSize * (this._currenPage-1)).toString());

            this.PushState(this._currenPage, previousePage);

            this.HandleNextClick();
            this.HandlePreviousClick();
            this.HandleNumbersChange();
        })
    }

    private AddNumbers() {
        for (let i = 1; this._numberOfPages >= i; i++) {
            let some = document.createElement('a');
            some.text = i.toString();

            if (this._currenPage === i) {
                some.classList.add('Current');
            }

            this.$_shopPagingNumbers.append(some);
        }
    }

    private HandleNumbersChange() {
        this.$_shopPagingNumbers.children().each((index, item) => {
            if ((index+1) === this._currenPage) {
                item.classList.add('Current');
            } else {
                item.classList.remove('Current');
            }
        })
    }

    private HandleNextClick() {
        if (this._currenPage > 1 && this.$_shopPagingPrevious.css('visibility') !== 'visible') {
            this.$_shopPagingPrevious.css('visibility', 'visible');
        }

        if (this._numberOfPages === this._currenPage) {
            this.$_shopPagingNext.css('visibility', 'hidden');
        }
    }

    private AddNext() {
        if (this._numberOfPages > 1 && this._currenPage !== this._numberOfPages) {
            this.$_shopPagingNext.css('visibility', 'visible');
        }

        this.$_shopPagingNext.children('input').click(() => {
            this._itemPicture.HandlePaging((this._pageSize * this._currenPage).toString());
            let previousePage = this._currenPage;
            this._currenPage++;

            this.PushState(this._currenPage, previousePage);

            this.HandleNextClick();
            this.HandleNumbersChange();
            console.log("from next Button");
        });

    }

    private HandlePreviousClick() {
        if (this._currenPage < this._numberOfPages && this.$_shopPagingNext.css('visibility') !== 'visible') {
            this.$_shopPagingNext.css('visibility', 'visible');
        }

        if (this._currenPage === 1) {
            this.$_shopPagingPrevious.css('visibility', 'hidden');
        }

    }

    private AddPrevious() {
        if (this._currenPage !== 1) {
            this.$_shopPagingPrevious.css('visibility', 'visible');
        }

        this.$_shopPagingPrevious.children('input').click(() => {
            if (this._currenPage <= 1) return;

            let previousePage = this._currenPage;
            this._currenPage--;
            this._itemPicture.HandlePaging((this._pageSize * (this._currenPage-1)).toString());

            this.PushState(this._currenPage, previousePage);

            this.HandlePreviousClick();
            this.HandleNumbersChange();
            console.log("from previous button");
        });
    }
}