import { WebshopItemCard } from "../Models/webshop-item-card";
import itemCardTemplate from '../Templates/ItemCard.handlebars';

declare var bootstrapClass;

export default class IsotopeHelper {
    public static AddAndRemoveElements(argContainer: JQuery<HTMLElement>, oldResult: WebshopItemCard[], newResult: WebshopItemCard[],
        argBootstrapClass: string = "col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12"): void {
        argContainer.isotope('remove', argContainer.find(".NoResult"));
        let localThis = this;

        if (oldResult !== null) {
            // Loop old results and find elements to remove
            for (let oldItem of oldResult) {
                var found = false;
                for (let newItem of newResult) {
                    if (oldItem.noField === newItem.noField && oldItem.uOMField === newItem.uOMField) {
                        found = true;
                        break;
                    }
                }

                if (!found) {
                    argContainer.isotope('remove', argContainer.find("[data-no='" + oldItem.noField + "'][data-uom='" + oldItem.uOMField + "']"));
                }
            }

            // Loop new results and find elements to add
            for (let item of newResult) {
                var found = false;
                for (let oldItem of oldResult) {
                    if (oldItem.noField === item.noField && oldItem.uOMField === item.uOMField) {
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    //argContainer.isotope('insert', $(localThis.CreateElement(item, argBootstrapClass)))
                    let elm = $(localThis.CreateElement(item, argBootstrapClass));
                    argContainer.isotope().append( elm ).isotope('appended', elm).isotope('layout');
                }
            }

            argContainer.isotope();
        } else {
            var newEls = '';
            for (let item of newResult) {
                newEls += this.CreateElement(item, argBootstrapClass);
            }
            var $newEls = $(newEls);

            document.getElementById(argContainer[0].id).innerHTML += this.CreateGridSizer(argBootstrapClass);
            argContainer.isotope('insert', $newEls);
        }

        if (newResult.length === 0) {
            let $nothingFoundElement = $('<div style="padding: 0 15px;" class="element col-xs-12 ItemPartial NoResult Inventory"><p>'
                + txtNothingFoundTryAdjustingYourSearchTermsToFindResults + '</p></div>');
            argContainer.isotope('insert', $nothingFoundElement);
        }

        BaseShop.LoadInventory();
    }

    private static CreateElement(argItem: WebshopItemCard, argBootstrapClass: string): string {
        let itemLink = `/nethandil/Item/Item/${argItem.noField}/${argItem.uOMField}`;
        if (argItem.foodInfoIDField)
            itemLink += `/${argItem.foodInfoIDField}`;

        let itemDescription = argItem.descriptionField;
        
        let bootstrap = argBootstrapClass;
        let biggerImage = "normalImage";

        if (argItem.webshop_Bigger_PictureField) {
            if (bootstrap.includes("bigger")) {
                biggerImage = "biggerImage";
                bootstrap = bootstrap.replace('col-xl-3', 'col-xl-6');
            }
        }

        let imageHeightTest = argItem.webshop_Bigger_PictureField ? '?width=600&height=600' : '?width=300&height=300';

        let itemImageLink = "";
        if (argItem.iDefaultPictureNoField > 0)
            itemImageLink = `/Images/Items/${companyName}/W450/${argItem.iDefaultPictureNoField}${argItem.coDefaultPictureExtensionField}`;

        let customerUnitPriceExclVat = argItem.deCustomerUnitPriceFieldSpecified ? argItem.deCustomerUnitPriceField : 0;
        let customerUnitPriceInclVat = argItem.deCustomerUnitPriceInclVATFieldSpecified ? argItem.deCustomerUnitPriceInclVATField : 0;

        if (argItem.unitOfMeasuresField !== null && argItem.unitOfMeasuresField.length > 1) {
            for (var i = 0; i < argItem.unitOfMeasuresField.length; i++) {
                if (argItem.unitOfMeasuresField[i].codeField === argItem.sales_Unit_of_MeasureField) {
                    customerUnitPriceExclVat = argItem.deCustomerUnitPriceFieldSpecified ? argItem.unitOfMeasuresField[i].deCustomerUnitPriceField : 0;
                    customerUnitPriceInclVat = argItem.deCustomerUnitPriceInclVATFieldSpecified ? argItem.unitOfMeasuresField[i].deCustomerUnitPriceInclVATField : 0;
                }
            }
        }

        if (argItem.deLineDiscPctFieldSpecified && argItem.deLineDiscPctField !== 0) {
            customerUnitPriceExclVat = customerUnitPriceExclVat / 100 * (100 - argItem.deLineDiscPctField);
            customerUnitPriceInclVat = customerUnitPriceInclVat / 100 * (100 - argItem.deLineDiscPctField);
        }

        let priceInclVatString = customerUnitPriceInclVat.toLocaleString("da-DK", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).replace(",00", ",-");
        let priceExclVatString = customerUnitPriceExclVat.toLocaleString("da-DK", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).replace(",00", ",-");
        let priceWithoutDiscountString = argItem.deUnitPriceInclVATField?.toLocaleString("da-DK", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).replace(",00", ",-") ?? "";

        let isItemForSale = argItem.webshop_No_SaleField; //&& customerUnitPriceInclVat === -1;

        let isItemOnDiscount = Math.round(customerUnitPriceInclVat) != Math.round(argItem.deUnitPriceInclVATField) && argItem.deUnitPriceField;
        let showDiscountLabel = customerNo === '' || defualtCustomerNo === customerNo;

        let showNewLabel = argItem.webshop_CommentField?.toLowerCase() == "nýtt";

        let viewObj = {
            itemCard: argItem,
            itemDescription: itemDescription,
            bootstrapClass: bootstrap,
            itemLink: itemLink,
            showNewLabel: showNewLabel,
            itemImageType: biggerImage,
            itemImageLink: itemImageLink,
            isItemComplex: !this.isItemComplex(argItem),
            itemIsNotForSaleOrComplex: isItemForSale || this.isItemComplex(argItem),
            itemPriceInclVat: priceInclVatString,
            itemPriceExclVat: priceExclVatString,
            itemPriceWithoutDiscountInclVat: priceWithoutDiscountString,
            isItemOnDiscount: isItemOnDiscount,
            showDiscountLabel: showDiscountLabel && isItemOnDiscount 
        }

        return itemCardTemplate(viewObj);
    }

    private static CreateGridSizer(argBootstrapClass: string) {
        var html = '<div class="grid-sizer ' + argBootstrapClass + '"></div>'
        return html;
    }

    private static isItemComplex(argItem: WebshopItemCard): boolean {
        var hasVariants = argItem.item_VariantsField?.length !== 0;
        if (argItem.cakePictureField || argItem.cakeTextOrPictureField || argItem.cakeText25Field || argItem.cakeText60Field || hasVariants || argItem.tilkeypCountField)
            return true;

        return false;
    }

    public static GetSpinner(): HTMLImageElement {
        let searchResultSpinnerImg = document.createElement("img");
        searchResultSpinnerImg.classList.add("SearchSpinner");
        searchResultSpinnerImg.src = "/Images/Spinner_16.gif";

        return searchResultSpinnerImg;
    }
}