import ItemPicturesV2 from "../../Base/BaseItemPictures";
import IsotopeHelper from "../../Base/IsotopeHelper";

export default class LoadMoreItems{
    private _parentElement: HTMLElement;
    private _itemPicture: ItemPicturesV2;
    private _totalNumberOfItems: number;
    private _loadedItems: number;
    private _pageSize: number;
    private _loadMoreContainer: HTMLDivElement;
    private _lazyLoadMoreItems;
    private _loadMoreSpinner: HTMLImageElement;
    private _isotopeHolder: HTMLDivElement;
    private _topofThePageDistance: number;

    constructor(itemPicture: ItemPicturesV2, totalNumberOfItems: number, loadedItems: number){ 
        this._itemPicture = itemPicture;
        this._totalNumberOfItems = totalNumberOfItems;
        this._loadedItems = loadedItems;
        this._pageSize = loadedItems;
        this._loadMoreSpinner = IsotopeHelper.GetSpinner();

        this._parentElement = document.getElementById("SwitchCategoryItemView").parentElement;
        //@ts-ignore
        this._lazyLoadMoreItems = _.debounce(async () => await this.LazyLoadMoreItems(), 500, true);

        this._isotopeHolder = document.getElementById(this._itemPicture._isotopeHolderId) as HTMLDivElement;
        this._topofThePageDistance = this._isotopeHolder.getBoundingClientRect().top + window.scrollY;

        this.Initialize();
    }

    public UpdateLoadMore(totalNumberOfItems: number){
        this._totalNumberOfItems = totalNumberOfItems;
        this._loadedItems = this._pageSize;
        if((this._totalNumberOfItems <= this._loadedItems) && this._loadMoreContainer){
            this._loadMoreContainer.classList.add("d-none");
            return;
        }

        if(!this._loadMoreContainer){
            this.Initialize();
            return;
        } else{
            this._loadMoreContainer.classList.remove("d-none");
        }
    }

    private Initialize(){
        if(this._totalNumberOfItems <= this._loadedItems) return;

        this._loadMoreContainer = document.createElement("div");
        this._loadMoreContainer.id = "LoadMoreItemsId";

        let span = document.createElement("span");
        span.innerHTML = "V\u00ED\s fleiri";
        span.classList.add("mr-2");

        span.onclick = () => this._lazyLoadMoreItems();
        this._loadMoreContainer.appendChild(span);
        this._loadMoreContainer.appendChild(this._loadMoreSpinner);

        window.addEventListener('scroll', async () => await this.WindowScrollListener());

        this._parentElement.appendChild(this._loadMoreContainer);
    }

    private async WindowScrollListener() {
        const {
            scrollTop, scrollHeight, clientHeight
        } = document.documentElement; //document.getElementById(this._itemPicture._isotopeHolderId);

        let isotopeScrollHeight = scrollTop - this._topofThePageDistance;

        let loadMoreItemsScroll = this._isotopeHolder.clientHeight - 500;

        //console.log(`ScrollHeight: ${isotopeScrollHeight}`);
        //console.log(`LoadMoreItems: ${loadMoreItemsScroll}`);

        if (isotopeScrollHeight > loadMoreItemsScroll && this._loadedItems < this._totalNumberOfItems) {
            await this._lazyLoadMoreItems();
        }
    }

    private async LazyLoadMoreItems() {
        console.log("load scroll");

        this.ShowHideSpinner(true);
        await this._itemPicture.LoadMoreItems(this._loadedItems.toString());
        this.ShowHideSpinner(false);

        this._loadedItems += this._pageSize;

        if(this._loadedItems >= this._totalNumberOfItems){
            this._loadMoreContainer.classList.add("d-none");
        } 
    }

    private async HandleLoadMoreClick(){
        this.ShowHideSpinner(true);
        await this._itemPicture.LoadMoreItems(this._loadedItems.toString());
        this.ShowHideSpinner(false);
        this._loadedItems += this._pageSize;

        if(this._loadedItems >= this._totalNumberOfItems){
            this._loadMoreContainer.classList.add("d-none");
        } 
    }

    private ShowHideSpinner(show: boolean) {
        if (show)
            this._loadMoreSpinner.style.display = "block";
        else
            this._loadMoreSpinner.style.display = "none";
    }
}