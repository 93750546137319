import HistoryEventManager from "./HistoryEventManager";
import { Location, Action } from "history";
import  history from 'history/browser';
import { IHistoryModel } from "./HistoryInterface";

export default class HistoryPublisher {
    public eventManager: HistoryEventManager;

    constructor() {
        this.eventManager = new HistoryEventManager();

        history.listen(({ location, action }) => this.listenForHistoryChanges(location, action))
    }

    private listenForHistoryChanges(location: Location, action: Action): void {
        if (action === Action.Pop) {
            this.handleHistoryPopAction(location);
        }
    }

    private handleHistoryPopAction(location: Location): void {
        if (!location.state) return;

        const locationState = location.state as IHistoryModel;
        if (location === null) return;

        this.eventManager.notify(locationState);
    }
}