import { HistoryObserver, IHistoryModel } from "../History/HistoryInterface";
import LoadMoreItems from "../Mylnan/ItemPictures/ShopLoadMore";
import { Paging } from "../Mylnan/ItemPictures/ShopPaging";
import AjaxCallHelper from "./AjaxCallHelper";
import IsotopeHelper from "./IsotopeHelper";

export default class ItemPicturesV2 implements HistoryObserver {
    private _isotopeGrid: JQuery;
    private _normalItemImages: HTMLCollectionOf<Element>;
    private _bigItemImages: HTMLCollectionOf<Element>;
    private _itemHeight: number;
    private _root: HTMLElement;
    private _pageSize: number;
    private _displayStart: number;
    private _totalNumberOfItems: number;
    private _items: IItemPictureModel;
    private _oldItems: IItemPictureModel;
    private _bootstrapClass: string;
    private _loadMore: LoadMoreItems;
    private _searchSpinner: HTMLElement;

    public _isotopeHolderId: string
    _paging: Paging;
    _categoryCode: string;

    constructor(isotopeHolder: string, categoryCode: string, pageSize: number, displayStart: number,
        items: IItemPictureModel, enablePaging = false, enableLoadMore = false,
        argBootstrapClass?: string, macro: boolean = false) {
        this._searchSpinner = document.getElementById("ItemPicturesSpinnerId");
        this._bootstrapClass = argBootstrapClass ?? "col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12";
        this._isotopeGrid = $("#" + isotopeHolder);
        this._isotopeHolderId = isotopeHolder;
        this._totalNumberOfItems = items.TotalItems;
        this._categoryCode = categoryCode;
        this._pageSize = pageSize;
        this._displayStart = displayStart;

        this._paging = enablePaging
            ? new Paging(this._pageSize, this._displayStart, this._totalNumberOfItems, this)
            : null;
        
        if(enableLoadMore) this._loadMore = new LoadMoreItems(this, this._totalNumberOfItems, this._pageSize);

        this._itemHeight = 0;
        this._root = document.documentElement;
        this._normalItemImages = document.getElementById(isotopeHolder).getElementsByClassName("normalImage");
        this._bigItemImages = document.getElementById(isotopeHolder).getElementsByClassName("biggerImage");

        this.insertGrid();

        this.loadItemPictures(items);
    }

    historyUpdate(data: IHistoryModel): void {
        this.ManulUpdateIsotopeLayout();
    }

    public async UpdateItemsByCategory(categoryCode: string, displayStart: number = 0, currentPage: number = 1) {
        if (categoryCode) this._categoryCode = categoryCode;

        this.ShowHideSearchSpinner(true);
        var items = await AjaxCallHelper.GetItems(categoryCode, this._pageSize.toString(), displayStart.toString());
        if (items === null) {
            this.ShowHideSearchSpinner(false);
            return;
        }

        if (this._paging) this._paging.ChangeCategory(items.TotalItems, currentPage);

        if(this._loadMore) this._loadMore.UpdateLoadMore(items.TotalItems);

        this.loadItemPictures(items, true);
    }

    public UpdateItems(items: IItemPictureModel) {
        this.loadItemPictures(items);
    }

    public async HandlePaging(displayStart: string) {
        this.ShowHideSearchSpinner(true);
        var items = await AjaxCallHelper.GetItems(this._categoryCode, this._pageSize.toString(), displayStart);
        if (items === null) {
            this.ShowHideSearchSpinner(false);
            return;
        }

        this.loadItemPictures(items);
    }

    public async LoadMoreItems(displayStart: string) {
        this.ShowHideSearchSpinner(true);
        var items = await AjaxCallHelper.GetItems(this._categoryCode, this._pageSize.toString(), displayStart);
        if (items === null) {
            this.ShowHideSearchSpinner(false);
            return;
        };
        
        this.AddMoreItems(items);
    }

    private AddMoreItems(items: IItemPictureModel){
        let temp = items.WebshopItemCards.concat(this._items.WebshopItemCards);

        IsotopeHelper.AddAndRemoveElements(this._isotopeGrid, this._items?.WebshopItemCards ?? null, temp, this._bootstrapClass);

        this.ShowHideSearchSpinner(false);
        BaseShop.SetupCartButtons();
        BaseShop.ReloadShopCart(true);
        this._items.WebshopItemCards = temp;
    }

    private loadItemPictures(items: IItemPictureModel, reloadShopCart: boolean = false) {
        this._totalNumberOfItems = items.TotalItems;

        IsotopeHelper.AddAndRemoveElements(this._isotopeGrid, this._items?.WebshopItemCards ?? null, items.WebshopItemCards, this._bootstrapClass);

        BaseShop.SetupCartButtons();
        if (reloadShopCart) {
            BaseShop.ReloadShopCart(true);
        }

        this.ShowHideSearchSpinner(false);
        $(".SearchSpinner").hide();

        if (!this._items) {
            this._isotopeGrid.imagesLoaded()
                .always((instance) => {
                    this.HandleLargerImage();
                    let minHeight = 0;
                    for (let item of instance.elements[0].children) {
                        if (item.firstChild === null) continue;
                        if (item.firstChild.clientHeight > minHeight) {
                            minHeight = item.firstChild.clientHeight;
                        }
                    }
                    this.setMinHeigt(minHeight + 30);
                    this._isotopeGrid.isotope('layout');

                })
                .progress((instane, image) => {
                    //this._isotopeGrid.isotope('layout');
                });

            this._isotopeGrid.off('layoutComplete');
            setTimeout(() => {
                this._isotopeGrid.on('layoutComplete', () => this.UpdateIsotopeLayout());
            }, 1000);
        } else {
            this._isotopeGrid.isotope('layout');
        }
        this._items = items;
    }

    private async UpdateIsotopeLayout() {
        this.HandleLargerImage();
        await this.Sleep(450);
        const parent = document.getElementById(this._isotopeHolderId);
        let minHeight = 0;
        for (let item of parent.children) {
            if (item.firstElementChild === null) continue;
            if (item.firstElementChild.clientHeight > minHeight && !item.firstElementChild.classList.contains("biggerPicture")) {
                minHeight = item.firstElementChild.clientHeight;
            }
        }
        minHeight = minHeight + 30;
        if (this._itemHeight === minHeight) return;

        this._itemHeight = minHeight;
        this.setMinHeigt(minHeight);
        var localThis = this;
        setTimeout(function () {
            localThis._isotopeGrid.isotope('layout');
        }, 100);
    }

    public ManulUpdateIsotopeLayout() {
        this._isotopeGrid.isotope('layout');
    }

    private insertGrid() {
        this._isotopeGrid.isotope({
            itemSelector: '.element',
            percentPosition: true,
            layoutMode: 'fitRows',
            //masonry: {
            //    columnWidth: '.grid-sizer'
            //},
            getSortData: {
                no: '[data-no]',
                sort: '[data-sort]',
                description: '[Description]'
            },
            sortBy: 'no',
            //sortBy: 'number',
            //sortBy: 'description',
            sortAscending: true,
        });
    }

    private Sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    private setMinHeigt(minHeight) {
        this._root.style.setProperty('--dynamicHeight', minHeight + "px");
    }

    private HandleLargerImage() {
        if (this._normalItemImages.length <= 0) return;
        let firstSmallImage = this._normalItemImages[0];

        for (let image of this._bigItemImages) {
            (image as HTMLElement).parentElement.parentElement.style.height = firstSmallImage.clientHeight.toString() + "px";
        }
    }

    private ShowHideSearchSpinner(show: boolean) {
        if (!this._searchSpinner) return;
        if (show) {
            this._searchSpinner.style.display = "block";
        } else {
            this._searchSpinner.style.display = "none";
        }
    }
}