var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"img-container\" style=\"width: 100%;\">\r\n                        <a href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"itemLink") : depth0), depth0))
    + "\">\r\n                            <img class=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"itemImageType") : depth0), depth0))
    + "\" src=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"itemImageLink") : depth0), depth0))
    + "\" />\r\n                        </a>\r\n                    </div>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"noPictureHolder\">\r\n                        <a href=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"itemLink") : depth0), depth0))
    + "\">\r\n                            <img style=\"max-width: 100%; height:auto;\" src=\"/Images/SMS/PlaceHolder.jpg?width=300&amp;height=300&amp;\">\r\n                            <span>Eingin mynd</span>\r\n                        </a>\r\n                    </div>\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"ReadMoreOuter\" style=\"margin-top: 15px;\" data-no=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"itemCard") : depth0)) != null ? lookupProperty(stack1,"noField") : stack1), depth0))
    + "\" data-uom=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"itemCard") : depth0)) != null ? lookupProperty(stack1,"uOMField") : stack1), depth0))
    + "\">\r\n                <a class=\"d-xl-flex justify-content-xl-center\" href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"itemLink") : depth0), depth0))
    + "\">\r\n                    <div class=\"ReadMore Clickable\">Vís meira</div>\r\n                </a>\r\n            </div>\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./AddToCart.handlebars"),depth0,{"name":"AddToCart","fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"itemCardComment\">\r\n                <div class=\"commentTextWrapper d-flex justify-content-center\">\r\n                    <span class=\"commentText\">                                                                         \r\n                        "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"itemCard") : depth0)) != null ? lookupProperty(stack1,"webshop_CommentField") : stack1), depth0))
    + "\r\n                    </span>\r\n                </div>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"showNewLabel") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":16},"end":{"line":48,"column":23}}})) != null ? stack1 : "")
    + "            </div>\r\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "                    <div class=\"d-flex w-100\">\r\n                        <div class=\"arrow-down arrow-down-left\"></div>\r\n                        <div class=\"arrow-down arrow-down-right\"></div>\r\n                    </div>\r\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"discountItem\">\r\n                <span>TILBOÐ</span>\r\n            </div>\r\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "                        <div></div>\r\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isItemOnDiscount") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(20, data, 0),"data":data,"loc":{"start":{"line":67,"column":24},"end":{"line":76,"column":31}}})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"d-flex justify-content-center flex-wrap\" style=\"gap: 10px;\">\r\n                                <div class=\"Price PriceMain PriceWithoutDiscount\" style=\"text-decoration: line-through; color: red;\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"itemPriceWithoutDiscountInclVat") : depth0), depth0))
    + "</div>\r\n                                <div class=\"Price PriceAlternative PriceMain CustomerUnitPriceExclVat Price4Chars\" data-no=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"itemCard") : depth0)) != null ? lookupProperty(stack1,"noField") : stack1), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"itemPriceExclVat") : depth0), depth0))
    + "</div>\r\n                                <div class=\"Price CustomerUnitPriceInclVat Price4Chars PriceOffer\" data-no=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"itemCard") : depth0)) != null ? lookupProperty(stack1,"noField") : stack1), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"itemPriceInclVat") : depth0), depth0))
    + "</div>\r\n                            </div>\r\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"Price PriceAlternative PriceMain CustomerUnitPriceExclVat Price4Chars\" data-no=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"itemCard") : depth0)) != null ? lookupProperty(stack1,"noField") : stack1), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"itemPriceExclVat") : depth0), depth0))
    + "</div>\r\n                            <div class=\"Price PriceMain CustomerUnitPriceExclVat Price4Chars\" data-no=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"itemCard") : depth0)) != null ? lookupProperty(stack1,"noField") : stack1), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"itemPriceInclVat") : depth0), depth0))
    + "</div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\r\n<div class=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"bootstrapClass") : depth0), depth0))
    + " element ItemPartial2\"\r\n     data-no=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"itemCard") : depth0)) != null ? lookupProperty(stack1,"noField") : stack1), depth0))
    + "\" data-uom=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"itemCard") : depth0)) != null ? lookupProperty(stack1,"uOMField") : stack1), depth0))
    + "\" data-sort=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"itemCard") : depth0)) != null ? lookupProperty(stack1,"webshop_CreatedField") : stack1), depth0))
    + "\" style=\"position: absolute; left: 0%; top: 0px;\">\r\n    <div class=\"d-flex w-100 flex-column\" style=\"height: fit-content; overflow: hidden;\">\r\n        <div class=\"ItemHolder\">\r\n\r\n            <div class=\"itemImage\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"itemCard") : depth0)) != null ? lookupProperty(stack1,"iDefaultPictureNoField") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":21,"column":23}}})) != null ? stack1 : "")
    + "            </div>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"itemIsNotForSaleOrComplex") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":24,"column":12},"end":{"line":33,"column":19}}})) != null ? stack1 : "")
    + "        </div>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"itemCard") : depth0)) != null ? lookupProperty(stack1,"webshop_CommentField") : stack1),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":8},"end":{"line":50,"column":15}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"showDiscountLabel") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":8},"end":{"line":56,"column":15}}})) != null ? stack1 : "")
    + "\r\n        <div class=\"ItemTextHolder\">\r\n            <a href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"itemLink") : depth0), depth0))
    + "\">\r\n                <div class=\"Description\">\r\n                    <h3>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"itemDescription") : depth0), depth0))
    + "</h3>\r\n                </div>\r\n                <div>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"itemHidePrice") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":64,"column":20},"end":{"line":77,"column":27}}})) != null ? stack1 : "")
    + "                </div>\r\n            </a>\r\n        </div>\r\n    </div>\r\n</div>\r\n\r\n";
},"usePartial":true,"useData":true});