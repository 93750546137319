import { Webshop_Sales_Line } from "../Models/webshop_-sales_-line";
import { Type } from "../Models/type";
import AjaxCallHelper from "./AjaxCallHelper";
import Noty from 'noty';

export default class BaseShop implements IBaseShop {
    private webshopSalesLines: Webshop_Sales_Line[];
    private divItemImageThumbnail: JQuery<HTMLDivElement>;
    private navCartNumberHolders: HTMLCollectionOf<HTMLDivElement>;

    public Initialize() {
        this.navCartNumberHolders = <HTMLCollectionOf<HTMLDivElement>>document.getElementsByClassName("numberHolder");
        this.SetupCartButtons();
    }

    private UpdateNavCartNumber(totalQuantity: number) {
        for (let elm of this.navCartNumberHolders) {
            if (totalQuantity === 0) {
                elm.classList.remove("numberHolderShow");

                $("#mobileCartItemTotal").html('');
                $("#CartItemCount, #mobileCartItemTotal").html("( "+totalQuantity.toFixed(0)+" vørur )");
            } else if (totalQuantity === 1) {
                elm.classList.add("numberHolderShow");
                elm.innerHTML = '<span>' + totalQuantity.toFixed(0) + '</span>';

                $("#CartItemCount, #mobileCartItemTotal").html("( "+totalQuantity.toFixed(0)+" vøra )");
                $("#CartItemCountLarge, #CartItemCountLargeMobile").html(totalQuantity.toFixed(0));
            } else {
                elm.classList.add("numberHolderShow");
                elm.innerHTML = '<span>' + totalQuantity.toFixed(0) + '</span>';

                $("#CartItemCount, #mobileCartItemTotal").html("( "+totalQuantity.toFixed(0)+" vørur )");
                $("#CartItemCountLarge, #CartItemCountLargeMobile").html(totalQuantity.toFixed(0));
            }
        }
    }

    public async ReloadShopCart(updateAddToCartButtons: boolean): Promise<void>{
        let webshopSalesLines = await AjaxCallHelper.SalesLineGetAll();
        this.webshopSalesLines = webshopSalesLines;

        $("#PopupCartContent").empty();

        if (webshopSalesLines === null)
            webshopSalesLines = new Array<Webshop_Sales_Line>();

        var totalLineAmountExclVat = 0;
        var totalLineAmountInclVat = 0;
        var totalQuantity = 0;
        var totalLineCost = 0;

        var content = "";
        $("#PopupCartView").show();
        $("#PopupCartAmount").show();

        content += "<div class='PopupCartTable'>";

        if (window.location.pathname.indexOf("CheckoutStep4") !== -1) {
            return;
        }
        if (webshopSalesLines.length === 0) {
            $("#PopupCartView").hide();
            $("#PopupCartAmount").hide();

            $("#PopupCartContent").append("<div class='PopupCartLine'>" + txtCartIsEmpty + "</div>");
        }

        for (let salesLine of webshopSalesLines) {
            if (salesLine.attached_to_Line_NoField !== 0) continue;

            let lineAmountExclVat = salesLine.line_AmountField / (100 + salesLine.vAT_PercentField) * 100;
            let lineAmountInclVat = salesLine.line_AmountField;

            console.log('line_AmountField: ' + salesLine.line_AmountField);
            console.log('lineAmountExclVat: ' + lineAmountExclVat);
            console.log('lineAmountInclVat: ' + lineAmountInclVat);

            let attachedLines = webshopSalesLines.filter(x => x.typeField === 2 && x.attached_to_Line_NoField === salesLine.line_NoField);
            let addonTotalCostInclVat = 0;
            let addonTotalCostExclVat = 0;

            for (let addon of attachedLines) {
                if (pricesInclVat && !salesHeaderInclVat) {
                    addonTotalCostInclVat += addon.line_AmountField / 100 * (100 + salesLine.vAT_PercentField);
                    addonTotalCostExclVat += addon.line_AmountField;
                } else {
                    addonTotalCostInclVat += addon.line_AmountField;
                    addonTotalCostExclVat += addon.line_AmountField / (100 + salesLine.vAT_PercentField) * 100;
                }
            }

            var attachedImageUpload = salesLine.attached_to_Line_NoField !== 0 && salesLine.descriptionField.startsWith("http");

            if (!salesLine.line_AmountFieldSpecified) {
                lineAmountExclVat = 0;
                lineAmountInclVat = 0;
            }

            lineAmountExclVat += addonTotalCostExclVat;
            lineAmountInclVat += addonTotalCostInclVat;
            totalQuantity += salesLine.quantityField;
            totalLineAmountExclVat += lineAmountExclVat;
            totalLineAmountInclVat += lineAmountInclVat;

            let line = "<div class='PopupCartLine' style='width: 100%'>" +
                            "<div class='Image' >";

            if (salesLine.iDefaultPictureNoField > 0)
                line += "<img style='max-width: unset;' src='/Images/Items/" + companyName + "/W30/" + salesLine.iDefaultPictureNoField + salesLine.coDefaultPictureExtensionField + "' />";
            else if (attachedImageUpload) {
                line += "<img style='max-width: 30px; max-heigt: 30px' src='" + salesLine.descriptionField + "' />"
                salesLine.descriptionField = "Mynd";
            }   
                

            line += "</div><div>";

            if (salesLine.typeField === 0)
                line += "<div class='Description Headline'>";
            else
                line += "<div class='Description'>";

            //line += data[i].descriptionField;
            line += salesLine.descriptionField;

            /*
            if (data[i].unit_of_MeasureField !== null)
                line += " (" + data[i].unit_of_MeasureField + ") ";

            if (data[i].variant_CodeField !== null)
                line += " (" + data[i].variant_CodeField + ") ";
            */

            line += "</div>";

            if (!attachedImageUpload)
                line += "<div class='Quantity'>Nøgd: " + salesLine.quantityField + "</div>";

            line += "</div>";

            if (lineAmountInclVat !== 0)
                //@ts-ignore
                line += "<div class='Amount'>" + $.number(lineAmountInclVat, 2, ',', '.') + "</div>";

            line += "</div>";

            let dataRow = salesLine;

            // Adjust quantities
            let $itemDiv;
            if (dataRow.unit_of_Measure_CodeField !== null && dataRow.variant_CodeField !== null)
                $itemDiv = $('div[data-no="' + dataRow.noField + '"][data-uom="' + dataRow.unit_of_Measure_CodeField + '"][data-variant="' + dataRow.variant_CodeField + '"]');
            else if (dataRow.unit_of_Measure_CodeField !== null)
                $itemDiv = $('div[data-no="' + dataRow.noField + '"][data-uom="' + dataRow.unit_of_Measure_CodeField + '"]');
            else if (dataRow.variant_CodeField !== null)
                $itemDiv = $('div[data-no="' + dataRow.noField + '"][data-variant="' + dataRow.variant_CodeField + '"]');
            else
                $itemDiv = $('div[data-no="' + dataRow.noField + '"]');
            if ($itemDiv.find(".AddToCartQuantity").is(":visible") === false) {
                $itemDiv.find(".AddToCart").hide();
                $itemDiv.find(".AddToCartQuantityValue").text(dataRow.quantityField);
                $itemDiv.find(".AddToCartQuantityValue").data("quantity", dataRow.quantityField);
                $itemDiv.find(".AddToCartQuantity").css("display", "flex");
                //$itemDiv.find(".AddToCartQuantity").show();
            }

            // Adjust total price
            var $cartItemPrice = $('.Cart .CartRow[data-lineno="' + dataRow.line_NoField + '"] .Price');
            $cartItemPrice.children('.Kronur').html(lineAmountInclVat.toFixed(2).toString().split('.')[0]);
            $cartItemPrice.children('.Oyru').html(lineAmountInclVat.toFixed(2).toString().split('.')[1]);

            var $cartItemDiscount = $('.Cart .CartRow[data-lineno="' + dataRow.line_NoField + '"] .Discount');
            //@ts-ignore
            $cartItemDiscount.html($.number(salesLine.line_Discount_PercentField, 1, ',', '.') + "%");

            if (salesLine.line_Discount_PercentField === 0) {
                $cartItemDiscount.html('');
            }

            var $cartItemQuantity = $('.Cart .CartRow[data-lineno="' + dataRow.line_NoField + '"] .Quantity');
            //@ts-ignore
            $cartItemQuantity.html($.number(salesLine.quantityField, 2, ',', '.'));

            // Show or hide inventory warning
            var $cartItemNotInStock = $('.Cart .CartRow[data-lineno="' + dataRow.line_NoField + '"] .NotInStock');
            var availableInventoryInt = parseInt($cartItemNotInStock.attr('data-availableinventory'));
            var quantityInt = salesLine.quantityField;

            if (quantityInt > availableInventoryInt)
                $cartItemNotInStock.show();
            else
                $cartItemNotInStock.hide();

            // Adjust Profit
            var $cartProfit = $('.Cart .Profit[data-lineno="' + dataRow.line_NoField + '"]');
            if (salesLine.unit_PriceField !== 0 && salesLine.unit_Cost_LCYField !== 0) {
                var lineCost = salesLine.unit_Cost_LCYField * salesLine.quantityField;

                totalLineCost += lineCost;

                this.SetProfit($cartProfit, salesLine.line_AmountField, lineCost);
            }

            content += line;
        }

        content += "</div>";

        let totalVATAmount = 0;

        console.log('pricesInclVat: ' + pricesInclVat);
        console.log('salesHeaderInclVat: ' + salesHeaderInclVat);

        totalVATAmount = totalLineAmountInclVat - totalLineAmountExclVat;

        $("#PopupCartContent").append(content);

        //@ts-ignore
        $("#PopupCartAmountValue, #CartSubtotalTop, #CartSubtotalBottom").html($.number(totalLineAmountInclVat, 2, ',', '.') + ' DKK');
        //@ts-ignore
        $("#CartSubtotalBottomExcelVat").html($.number(totalLineAmountExclVat, 2, ',', '.') + ' DKK');
        //@ts-ignore
        $("#CartSubtotalBottomVATAmount").html($.number(totalVATAmount, 2, ',', '.') + ' DKK');
        //@ts-ignore
        $("#CartSubtotalBottomAlternative").html($.number(totalLineAmountInclVat * parseFloat($("#hCurrencyFactorEUR").val()), 0, ',', '.'));

        //@ts-ignore
        $('.Kronur').number(true, 0, ',', '.');

        let $cartProfitTotal = $('.Cart .ProfitTotal');
        this.SetProfit($cartProfitTotal, totalLineAmountExclVat, totalLineCost);

        this.UpdateNavCartNumber(totalQuantity);

        if ($('.Cart .CartRow .NotInStock:visible').length > 0) {
            $("#bBuy").hide();
            $("#bBuyConfirmation").show();
        }
        else {
            $("#bBuy").show();
            $("#bBuyConfirmation").hide();
        }
    }

    public SetProfit(cartProfit, lineAmountWithoutVAT: number, lineCost: number): void {
        let Profit = lineAmountWithoutVAT - lineCost;
        let ProfitPercent = Profit / lineAmountWithoutVAT * 100;

        cartProfit.children(".Percent").html(ProfitPercent.toFixed(2).toString().replace(".", ","));
        cartProfit.children(".KronurOgOyru").html(Profit.toFixed(2).toString().replace(".", ","));

        cartProfit.children(".KronurOgOyru").number(true, 2, ',', '.');

        cartProfit.children(".Percent").append(" %");
        cartProfit.children(".KronurOgOyru").append(" kr");
    }

    public QuantityShowOrHideAddToCart(argNo: string, argUom: string, argVariant: string, argQuantity: number): void {
        //console.log('QuantityShowOrHideAddToCart ' + argNo + ' ' + argUom + ' ' + argVariant + ' ' + argQuantity);

        if (argVariant === undefined || argVariant === '')
            argVariant = null;

        var $itemDiv;
        if (argUom !== null && argVariant !== null)
            $itemDiv = $('div.AddToCartOuter[data-no="' + argNo + '"][data-uom="' + argUom + '"][data-variant="' + argVariant + '"]');
        else if (argUom !== null)
            $itemDiv = $('div.AddToCartOuter[data-no="' + argNo + '"][data-uom="' + argUom + '"]');
        else if (argVariant !== null)
            $itemDiv = $('div.AddToCartOuter[data-no="' + argNo + '"][data-variant="' + argVariant + '"]');
        else
            $itemDiv = $('div.AddToCartOuter[data-no="' + argNo + '"]');

        var $addToCart = $itemDiv.find(".AddToCart");

        if (this.webshopSalesLines && argQuantity === -1) {
            for (let salesLine of this.webshopSalesLines) {
                if (salesLine.typeField !== Type.Item) continue;
                if (salesLine.noField === argNo
                    && salesLine.unit_of_Measure_CodeField === argUom
                    && salesLine.variant_CodeField === argVariant) {
                    argQuantity = salesLine.quantityField;
                };
            }
        }

        if (argQuantity > 0) {
            $addToCart.hide();
            $itemDiv.find(".AddToCartQuantityValue").text(argQuantity);
            $itemDiv.find(".AddToCartQuantityValue").data("quantity", argQuantity);
            //$itemDiv.find(".AddToCartQuantity").show();
            $itemDiv.find(".AddToCartQuantity").css("display", "flex");
        }
        else {
            var $addToCartQuantity = $addToCart.siblings(".AddToCartQuantity");

            $addToCartQuantity.fadeOut(100, function () {
                $addToCart.fadeIn(100);
            });
        }

    }

    public QuantityAdjustAddToCart(argAddToCart, argQuantity: number): void {
        let $addToCart = $(argAddToCart);
        let $addToCartQuantity = $addToCart.siblings(".AddToCartQuantity");

        $addToCartQuantity.find(".AddToCartQuantityInput").hide();
        $addToCartQuantity.find(".AddToCartQuantityValue").text(argQuantity);
        $addToCartQuantity.find(".AddToCartQuantityValue").data("quantity", argQuantity);

        if (argQuantity <= 0) {
            $addToCartQuantity.fadeOut(100, function () {
                $addToCart.fadeIn(100);
            });
        }
        else {
            $addToCart.fadeOut(100, function () {
                $addToCartQuantity.fadeIn(100).css("display", "flex");
            });
        }
    }

    public UpdatePrice(argPriceDiv, argPrice): void {
        if (argPriceDiv.find(".Kronur").length === 1) {
            //@ts-ignore
            argPriceDiv.find('.Kronur').html($.number(parseFloat(argPrice).toFixed(2).toString().split('.')[0], 0, ',', '.'));
            argPriceDiv.find('.Oyru').html(parseFloat(argPrice).toFixed(2).toString().split('.')[1]);
        }
        else {
            //@ts-ignore
            argPriceDiv.html($.number(argPrice, 2, ',', '.'));
        }
    }

    public SetupCartButtons(): void {
        //console.log('SetupCartButtons');

        $(".AddToCartUOMDropdown").unbind('change');
        $(".AddToCartUOMDropdown").change(event => {
            //console.log('AddToCartUOMDropdown');

            var $addToCartUOMDropdown = $(event.currentTarget);

            // Update add to cart button
            var $addToCartOuter = $(".AddToCartOuter[data-no='" + $addToCartUOMDropdown.attr('data-itemno') + "']");

            $addToCartOuter.attr('data-uom', <string>$addToCartUOMDropdown.val());
            $addToCartOuter.attr('title', <string>$addToCartUOMDropdown.val());

            this.QuantityShowOrHideAddToCart($addToCartUOMDropdown.attr('data-itemno'), $addToCartOuter.attr('data-uom'), $addToCartOuter.attr('data-variant'), -1)

            // Update price
            var $unitPriceExclVat = $(".Price.UnitPriceExclVat[data-no='" + $addToCartUOMDropdown.attr('data-itemno') + "']");
            this.UpdatePrice($unitPriceExclVat, $addToCartUOMDropdown.children("option:selected").attr('data-unitPriceExclVat'));

            var $unitPriceInclVat = $(".Price.UnitPriceInclVat[data-no='" + $addToCartUOMDropdown.attr('data-itemno') + "']");
            this.UpdatePrice($unitPriceInclVat, $addToCartUOMDropdown.children("option:selected").attr('data-unitPriceInclVat'));

            var $customerUnitPriceInclVat = $(".Price.CustomerUnitPriceInclVat[data-no='" + $addToCartUOMDropdown.attr('data-itemno') + "']");
            this.UpdatePrice($customerUnitPriceInclVat, $addToCartUOMDropdown.children("option:selected").attr('data-customerUnitPriceInclVat'));
            $addToCartOuter.attr('data-price', $addToCartUOMDropdown.children("option:selected").attr('data-customerUnitPriceInclVat'));

            var $customerUnitPriceExclVat = $(".Price.CustomerUnitPriceExclVat[data-no='" + $addToCartUOMDropdown.attr('data-itemno') + "']");
            this.UpdatePrice($customerUnitPriceExclVat, $addToCartUOMDropdown.children("option:selected").attr('data-customerUnitPriceExclVat'));
        });

        $(".AddToCartVariantDropdown").unbind('change');
        $(".AddToCartVariantDropdown").change(event => {
            //console.log("AddToCartVariantDropdown");

            var $addToCartVariantDropdown = $(event.currentTarget);

            // Update add to cart button
            var $addToCartOuter = $(".AddToCartOuter[data-no='" + $addToCartVariantDropdown.attr('data-itemno') + "']");

            let selectedVariant = <string>$addToCartVariantDropdown.val();

            var $addToCart = $addToCartOuter.children().first();
            if ($addToCart && $addToCart.hasClass("disabled") && selectedVariant) {

                $addToCart.removeClass("disabled");
                if ($addToCart.is("input")) {
                    $addToCart.prop("disabled", false);
                } else {
                    $addToCart.addClass("Clickable");
                }
            }

            if ($addToCart && !selectedVariant) {
                $addToCart.addClass("disabled")

                if ($addToCart.is("input")) {
                    $addToCart.prop("disabled", true);
                } else {
                    $addToCart.removeClass("Clickable");
                }
            }

            $addToCartOuter.attr('data-variant', selectedVariant);
            $addToCartOuter.attr('title', selectedVariant);

            this.QuantityShowOrHideAddToCart($addToCartVariantDropdown.attr('data-itemno'), $addToCartOuter.attr('data-uom'), $addToCartOuter.attr('data-variant'), -1);

        });

        $(".AddToCart").unbind('click');
        $(".AddToCart").click(async event => {
            //console.log('AddToCart');
            var $addToCart = $(event.currentTarget);

            if ($addToCart.hasClass("Clickable")) {
                $addToCart.removeClass("Clickable");

                var oldContent = $addToCart.html();
                $addToCart.addClass("Spinner");
                $addToCart.html("<img src='/Images/Spinner_16.gif' />");

                var unitOfMeasure = '';
                if ($addToCart.closest('.AddToCartOuter').attr('data-uom'))
                    unitOfMeasure = $addToCart.closest('.AddToCartOuter').attr('data-uom');

                var variant = '';
                if ($addToCart.closest('.AddToCartOuter').attr("data-variant"))
                    variant = $addToCart.closest('.AddToCartOuter').attr('data-variant');

                var variantAndItemDescription = '';
                if (variant) {
                    const variantHolder = <HTMLSelectElement>document.getElementById("variantsSelectId");
                    if (variantHolder?.dataset.itemdescription)
                        variantAndItemDescription += variantHolder.dataset.itemdescription;

                    let variants = <HTMLCollectionOf<HTMLDivElement>>variantHolder.children;
                    for (var i = 0; i < variants.length; i++) {
                        if (variants[i].id === variant) {
                            variantAndItemDescription += ` ${variants[i].dataset.variantdesciption}`;
                            break;
                        }
                    }
                }
                // console.log('variant: ' + variant);

                var variantCount = parseInt($addToCart.closest('.AddToCartOuter').attr('data-variantcount'));
                if (variantCount > 0 && variant === '') {
                    ErrorHandling.SendJavascriptErrorToServer($addToCart.closest('.AddToCartOuter').attr('data-no'));
                    this.NotyError('Eingin variantur valdur');

                    return;
                }
                var itemNo = $addToCart.closest('.AddToCartOuter').attr('data-no');

                let salesLine = await AjaxCallHelper.SalesLineAddItem(itemNo, unitOfMeasure, variant, variantAndItemDescription);

                this.ReloadShopCart(true);
                this.NotySuccess(salesLine);

                $addToCart.html(oldContent);
                $addToCart.removeClass("Spinner");
                $addToCart.addClass("Clickable");
            }
        });

        $(".AddToCartQuantityPlus, .AddToCartQuantityMinus").unbind('click');
        $(".AddToCartQuantityPlus, .AddToCartQuantityMinus").click(async event => {
            let current = $(event.currentTarget);
            if (!current.hasClass("Clickable")) return;

            var $addToCartQuantity = current.closest(".AddToCartQuantity");

            $addToCartQuantity.find(".AddToCartQuantityInput").hide();
            $addToCartQuantity.find(".AddToCartQuantityMinus, .AddToCartQuantityValue, .AddToCartQuantityPlus").removeClass("Clickable");

            var $addToCart = $addToCartQuantity.siblings(".AddToCart");
            var $quantity = <number>current.data("quantity");
            var $addToCartQuantityValue = $addToCartQuantity.find(".AddToCartQuantityValue");
            var quantityAfter = 0;

            $addToCartQuantityValue.html("<img src='/Images/Spinner_32.gif' height='" + ($addToCartQuantityValue.height() - 16) + "' />");

            var unitOfMeasure = '';
            if ($addToCart.closest('.AddToCartOuter').attr('data-uom'))
                unitOfMeasure = $addToCart.closest('.AddToCartOuter').attr('data-uom');

            var variant = '';
            if ($addToCart.closest('.AddToCartOuter').attr("data-variant"))
                variant = $addToCart.closest('.AddToCartOuter').attr('data-variant');

            var itemNo = $addToCartQuantity.closest('.AddToCartOuter').attr('data-no');

            let salesLine = await AjaxCallHelper.SalesLineAddItem(itemNo, unitOfMeasure, variant, '', $quantity);

            var cartRow = $addToCart.closest('.CartRow');
            quantityAfter = this.WebshopSalesLineAddItemSuccess(cartRow, salesLine);
            var dataLineNo = cartRow.data("lineno");
            if (dataLineNo) {
                var getAttached = document.getElementById("BasketTableId").querySelectorAll('[data-attachedToLine="' + dataLineNo + '"]');
                getAttached.forEach(function (elm) {
                    elm.remove();
                });
            }

            this.QuantityAdjustAddToCart($addToCart, quantityAfter);
            this.ReloadShopCart(false);
            $addToCartQuantity.find(".AddToCartQuantityMinus, .AddToCartQuantityValue, .AddToCartQuantityPlus").addClass("Clickable");
        });

        $(".Cart .RemoveItemsCell").unbind('click');
        $(".Cart .RemoveItemsCell").click(async event => {
            let current = $(event.currentTarget);

            var $row = current.closest(".CartRow");

            current.html("<img src='/Images/Spinner_16.gif' />");

            const lineNo = current.attr('data-lineno');

            await AjaxCallHelper.SalesLineRemoveLineNo(lineNo);

            let dataLineNo = $row.data('lineno').toString();
            $(".CartRow").each((index, row) => {
                if (row.dataset.lineno === dataLineNo || row.dataset.attachedtoline === dataLineNo) {
                    $(row).remove();
                }
            });
            this.ReloadShopCart(false);
        });

        $(".AddToCartQuantityValue").unbind('click');
        $(".AddToCartQuantityValue").click(event => {
            let current = $(event.currentTarget);
            if (!current.hasClass("Clickable")) return;

            var $AddToCartQuantityInput = current.parent().siblings(".AddToCartQuantityInput");
            $AddToCartQuantityInput.fadeIn(100);
            $AddToCartQuantityInput.find(".QuantityInputText").val(current.text());
            $AddToCartQuantityInput.find(".QuantityInputText").data("quantity", current.text());
            $AddToCartQuantityInput.find(".QuantityInputText").select();
            ($AddToCartQuantityInput.find(".QuantityInputText")[0] as HTMLInputElement).setSelectionRange(0, 9999);
        });

        $(".QuantityInputText, .UnitPriceInputText").unbind('keydown');
        $(".QuantityInputText, .UnitPriceInputText").keydown(event => {
            // Handle enter 
            if (event.keyCode === 13) {
                let current = $(event.currentTarget);

                if (current.attr('class') === "UnitPriceInputText") {
                    current.siblings(".UnitPriceInputButton").click();
                }

                if (current.attr('class') === "QuantityInputText") {
                    current.closest(".AddToCartQuantityInput").fadeOut(100);

                    var $addToCartQuantityValue = current.closest(".AddToCartQuantity").find(".AddToCartQuantityValue");
                    $addToCartQuantityValue.html("<img src='/Images/Spinner_32.gif' height='" + ($addToCartQuantityValue.height() - 16) + "' />");

                    current.siblings(".QuantityInputButton").click();
                }

                event.preventDefault();
            }
            else {
                // Allow special chars + arrows 
                if (event.keyCode === 46 || event.keyCode === 8 || event.keyCode === 9
                || event.keyCode === 27 || event.keyCode === 65 && event.ctrlKey === true
                || event.keyCode >= 35 && event.keyCode <= 39) {
                    return;
                } else {
                    // If it's not a number stop the keypress
                    if (event.shiftKey || (event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105)) {
                        event.preventDefault();
                    }
                }
            }
        });

        $(".QuantityInputButton").unbind('click');
        $(".QuantityInputButton").click(async event => {
            var $QuantityInputButton = $(event.currentTarget);
            var $addToCartQuantityValue = $QuantityInputButton.closest('.AddToCartOuter').find(".AddToCartQuantityValue");
            var $addToCart = $QuantityInputButton.closest('.AddToCartOuter').find(".AddToCart");
            var quantityNew = <number>$QuantityInputButton.siblings(".QuantityInputText").val();
            var quantityToAdd = quantityNew - $addToCartQuantityValue.data("quantity");
            var quantityAfter = 0;

            $QuantityInputButton.closest('.AddToCartOuter').find(".AddToCartQuantityMinus, .AddToCartQuantityValue, .AddToCartQuantityPlus").removeClass("Clickable");

            $addToCartQuantityValue.html("<img src='/Images/Spinner_32.gif' height='" + ($addToCartQuantityValue.height() - 2) + "' />");

            var unitOfMeasure = '';
            if ($addToCart.closest('.AddToCartOuter').attr('data-uom'))
                unitOfMeasure = $addToCart.closest('.AddToCartOuter').attr('data-uom');

            var variant = '';
            if ($addToCart.closest('.AddToCartOuter').attr("data-variant"))
                variant = $addToCart.closest('.AddToCartOuter').attr('data-variant');

            let itemNo = $addToCartQuantityValue.closest('.AddToCartOuter').attr('data-no');

            let salesLines = await AjaxCallHelper.SalesLineAddItem(itemNo, unitOfMeasure, variant, '', quantityToAdd);

            var cartRow = $QuantityInputButton.closest('.CartRow');
            quantityAfter = this.WebshopSalesLineAddItemSuccess(cartRow, salesLines);

            this.QuantityAdjustAddToCart($addToCart, quantityAfter);
            this.ReloadShopCart(false);
            $QuantityInputButton.closest('.AddToCartOuter').find(".AddToCartQuantityMinus, .AddToCartQuantityValue, .AddToCartQuantityPlus").addClass("Clickable");
        });

        $(".Cart .PriceStk.Modifyable").unbind('click');
        $(".Cart .PriceStk.Modifyable").click(event => {
            let current = $(event.currentTarget);
            var $CartPriceStkInput = current.siblings(".Cart .PriceStkInputHolder").children(".Cart .PriceStkInput");

            $CartPriceStkInput.fadeIn(100);
            $CartPriceStkInput.find(".UnitPriceInputText").val(current.children(".UnitPrice").text().replace(".", ""));
            $CartPriceStkInput.find(".UnitPriceInputText").select();
        });

        $(".UnitPriceInputButton").unbind('click');
        $(".UnitPriceInputButton").click(async event => {
            var $UnitPriceInputButton = $(event.currentTarget);
            var $addToCartQuantityValue = $UnitPriceInputButton.siblings('.UnitPriceInputText');
            var $CartPriceStkInput = $(this).closest(".PriceStkInput");
            var $CartPriceStkInputHolder = $(this).closest(".PriceStkInputHolder");
            var $CartPriceStkUnitPrice = $CartPriceStkInputHolder.siblings(".PriceStk").children(".UnitPrice");
            var unitPrice = ($UnitPriceInputButton.siblings(".UnitPriceInputText").val() as string).replace(",", ".");

            let lineNo = $addToCartQuantityValue.closest('.PriceStkInput').attr('data-lineno');

            let result = await AjaxCallHelper.SalesLineChangeUnitPrice(lineNo, unitPrice);

            this.ReloadShopCart(false);
            $CartPriceStkInput.fadeOut(100);

            let resultAsString = result.toString().replace(".", ",");

            $CartPriceStkUnitPrice.html(resultAsString);
            //@ts-ignore
            $CartPriceStkUnitPrice.number(true, 2, ',', '.');
        })

        $(".ItemImageThumbnail").unbind('hover');
        $(".ItemImageThumbnail").hover(event => {
            let current = $(event.currentTarget);
            if (this.divItemImageThumbnail === undefined) {
                this.divItemImageThumbnail = $("<div id='DivItemImageThumbnail' onclick='$(this).hide();'></div>");
                $("body").append(this.divItemImageThumbnail);
                $('#DivItemImageThumbnail').on('touchend click', function (e) {
                    e.stopPropagation();
                    e.preventDefault();
                    $(this).hide();
                });
            }

            this.divItemImageThumbnail.show();
            this.divItemImageThumbnail.offset({ top: current.offset().top, left: current.offset().left + 32 });
            this.divItemImageThumbnail.html('<img src="' + current.data('image') + '" />');
        }, event => {
            $('#DivItemImageThumbnail').hide();
        });
        $(document).on('click touchstart', function () {
            //$('#DivItemImageThumbnail').hide();
        });

        $(".AddToCartQuantityValue.Clickable").tipsy();
        $("#SwitchCategoryItemView img").tipsy();
        $(".TipsyTooltip").tipsy({ gravity: 'n' });
    }

    public WebshopSalesLineAddItemSuccess(cartRow, json): number {
        var quantityAfter = 0;
        if (json !== null)
            quantityAfter = json.quantityField;

        if (quantityAfter <= 0) {
            if (cartRow.length > 0) {
                cartRow.remove();
                $('.tipsy:last').remove();
            }
        }

        return quantityAfter;
    }

    public async LoadInventory(): Promise<void> {
        var itemsOnPage = "";
        $("div.ItemPartial").each(function () {
            if ($(this).data("no") !== undefined)
                itemsOnPage += "'" + $(this).data("no") + "'|";
        });

        if (itemsOnPage.length > 0) {
            itemsOnPage = itemsOnPage.substring(0, itemsOnPage.length - 1);

            let itemInventory = await AjaxCallHelper.SalesLineGetItemsInventory(itemsOnPage);

            for (let item of itemInventory) {
                var $itemPartial = $("div.ItemPartial[data-no='" + item.noField + "']");

                if (item.inventoryField === 0) {
                    $itemPartial.addClass("NoInventory");
                    $itemPartial.find('div.InventoryColorIndicator').attr("title", txtSoldOut);
                }
                else if (item.inventoryField === 1) {
                    $itemPartial.addClass("SomeInventory");
                    $itemPartial.find('div.InventoryColorIndicator').attr("title", txtFewInStock);
                }
                else {
                    $itemPartial.addClass("InInventory");
                    $itemPartial.find('div.InventoryColorIndicator').attr("title", txtInStock);
                }

            }
        }

        $("div.ItemPartial div.InventoryColorIndicator").tipsy({ gravity: 'e' });
    }

    public ShowShopCart(): void {
        if ($("#PopupCart").is(":visible"))
            $("#PopupCart").fadeOut();
        else
            $("#PopupCart").fadeIn();
    }
    
    public NotySuccess(json: INotySuccessModel): void {
        var timeout = 5000;
        if (webshopSeller)
            timeout = 300;

        var lineAmount = json.line_AmountField;
        // if (pricesInclVat && !salesHeaderInclVat)
            // lineAmount = json.line_AmountField / 100 * (100 + json.vAT_PercentField);

        let notyMain = document.createElement("div");

        let notyImage = document.createElement("div");
        notyImage.classList.add("d-flex", "p-4");
        notyImage.style.maxHeight = "130px";
        notyImage.style.justifyContent = "center";
        notyImage.classList.add("notyImageCell");

        if (json.iDefaultPictureNoField > 0) {
            let img = document.createElement("img");
            img.style.objectFit = "contain";
            img.src = `/Images/Items/${companyName}/W130/${json.iDefaultPictureNoField}${json.coDefaultPictureExtensionField}`;
            notyImage.appendChild(img);
        }

        let notyCell = document.createElement("div");

        var qtyLine = json.quantityField;

        let notyText = document.createElement("div");
        notyText.textContent = `${qtyLine} ${json.descriptionField}`;

        let notyPrice = document.createElement("div");
        notyPrice.textContent = `kr ${$["number"](lineAmount, 2, ',', '.')}`

        let notyButtonHolder = document.createElement("div");
        notyButtonHolder.classList.add("d-flex", "justify-content-end");

        let notybuttonInput = document.createElement("input");
        notybuttonInput.type = "button";
        notybuttonInput.value = "Til Kassan";
        notybuttonInput.classList.add("btn", "btn-primary");

        notybuttonInput.setAttribute("onclick", "location.href = \'/nethandil/checkout/CheckoutStep1\'");

        notyButtonHolder.appendChild(notybuttonInput);

        notyCell.appendChild(notyText);
        notyCell.appendChild(notyPrice);
        notyCell.appendChild(notyButtonHolder);

        notyMain.appendChild(notyImage);
        notyMain.appendChild(notyCell);

        var item = "<div class='notyTable'>" +
                "<div class='notyImageCell'>";

        if (json.iDefaultPictureNoField > 0)
            item += "<img src='/Images/Items/W30/" + json.iDefaultPictureNoField + json.coDefaultPictureExtensionField + "' />";

        item += "</div>" +
        //@ts-ignore
            "<div class='notyTextCell'><div class='notyText'>" + qtyLine + " " + json.descriptionField + "</div><div class='notyPrice'>kr " + $.number(lineAmount, 2, ',', '.') + "</div></div>" +
            "<div class='notyButtonCell'><input type='button' value='Til kassan' class='btn btn-primary' onclick=\"location.href = \'/nethandil/checkout/CheckoutStep1\';\" /></div>" +
            "</div>";

        new Noty({
            type: 'success',
            layout: 'bottomRight',
            timeout: timeout,
            theme: 'bootstrap-v4',
            text: notyMain.outerHTML,
        }).show();
    }

    public NotyEndUserError(errorMessage): void {
        var errorMsgContainer = document.createElement("div");
        errorMsgContainer.style.color = "red";

        errorMsgContainer.textContent = errorMessage;

        new Noty({
            type: 'success',
            layout: 'bottomRight',
            timeout: 10000,
            theme: 'bootstrap-v4',
            text: errorMsgContainer.innerHTML,
        }).show();
    }

    public NotyError(argExceptionMessage): void {
        Noty.setMaxVisible(1);
        let noty = new Noty({
            type: 'error',
            layout: 'bottomCenter',
            theme: 'webshopTheme',
            timeout: 100000,
            text: "Ein feilur er hendur. Vinarliga vend tær til eigarin av heimasíðuni og boða frá feilinum. Feilboðini eru:<br/>" + argExceptionMessage
        }).show();
    }
}