import { HistoryObserver, HistoryState, IHistoryModel } from "../History/HistoryInterface";
import HistorySingleton from "../History/HistorySingleton";
import MylnanHistoryModel from "../Models/MylnanHistoryModel";
import AjaxCallHelper from "./AjaxCallHelper";
import ItemPicturesV2 from "./BaseItemPictures";

export default class BaseSearchV2 implements HistoryObserver {
    private _searchItemPicture: ItemPicturesV2;
    private _searchWordListener: JQuery;
    private timerid;
    private _history: HistorySingleton;
    private _isotoperSearchContainer: HTMLElement;
    private _searchHolderContainer: HTMLElement;
    private _currentSearchWord: string;

    public _mainHolder: HTMLElement;
    public _searchCount: number;
    constructor() {
        this._isotoperSearchContainer = document.getElementById("IsotopeSearchContainer");
        this._mainHolder = document.getElementById("mainHolderId");
        this._searchCount = 0;

        this._history = HistorySingleton.getInstance();
        this.ReplaceState();
        this._searchWordListener = $(".iSearchWord").keyup((event) => {
            let current: HTMLInputElement = event.currentTarget as HTMLInputElement;
            let currentInput = current.value;

            if (event.keyCode === 13) {
                clearTimeout(this.timerid);
                this.PerformSearch(currentInput);
            } else {
                if (event.keyCode !== 9 && event.keyCode !== 16 && event.keyCode !== 17 && event.keyCode !== 18 && event.keyCode !== 33 && event.keyCode !== 34 && event.keyCode !== 91) {
                    clearTimeout(this.timerid);
                    this.timerid = setTimeout(() => { this.PerformSearch(currentInput); }, 500);
                }
            }
        });
    }

    async historyUpdate(data: IHistoryModel): Promise<void> {
        //console.log("Search history pop: " + data.historyState);
        if (data.historyState === HistoryState.LeftMenu) {
            this.HandleSearchVisible();
            return;
        }
        if (data.historyState === HistoryState.Search) {
            this._searchCount = data.searchCount;
            await this.PerformSearch(data.searchWord, false);
            return;
        }
        if (data.historyState === HistoryState.Default) {
            this.HandleSearchVisible();
        }
    }

    private HandleSearchVisible() : void {
        if (this._mainHolder.classList.contains("slideLeftMain")) {
            this.SearchTransitionOut();
        }
    }

    public async PerformSearch(argSearchWord?: string, saveHistory: boolean = true) {
        console.log('PerformSearch. topCategoryCode' + topCategoryCode);

        let searchWord = argSearchWord ?? $("#iSearchWord").val() as string;

        if (searchWord.length < 3) return;
        this._currentSearchWord = searchWord;
        $(".SearchSpinner").show();

        var searchItemsResult = await AjaxCallHelper.GetSearchItems(customerNo, userGuid, topCategoryCode, searchWord);
        this.ExecuteSearch(searchItemsResult);

        this.UpdateResultCount(searchItemsResult.TotalItems);
        this.UpdateSearchWord(searchWord);

        if (saveHistory) {
            this.SaveHistory(searchWord);
        }
    }

    private ExecuteSearch(searchItems: IItemPictureModel) {
        if (!this._searchItemPicture) {

            let wrapperContainer = document.createElement("div");
            wrapperContainer.style.width = "100%";
            wrapperContainer.id = "searchHolderId";
            wrapperContainer.style.marginRight = "-100%";
            wrapperContainer.style.opacity = "0";
            wrapperContainer.classList.add("slideTransition");

            let searchContainer = document.createElement("div");
            searchContainer.classList.add("container-md");
            searchContainer.classList.add("mt-4");

            //Search Header
            let searchResultHeader = document.createElement("div");
            let close = document.createElement("div");
            close.classList.add("searchBack");
            close.textContent = "Aftur";
            close.onclick = () => this.GoBack();

            let searchResultInformation = document.createElement("h2");
            searchResultInformation.id = "ResultInformation";
            let searchResultTitleSpan = document.createElement("span");
            searchResultTitleSpan.id = "ResultTitle";
            let searchResultCount = document.createElement("span");
            searchResultCount.id = "ResultCount";
            let searchResultSpinnerImg = document.createElement("img");
            searchResultSpinnerImg.classList.add("SearchSpinner");
            searchResultSpinnerImg.src = "/Images/Spinner_16.gif";
            searchResultHeader.appendChild(close);

            searchResultInformation.appendChild(searchResultTitleSpan);
            searchResultInformation.appendChild(searchResultCount);
            searchResultInformation.appendChild(searchResultSpinnerImg);

            searchResultHeader.appendChild(searchResultInformation);

            // Search Item Picture Container
            let searchItemPictures = document.createElement("div");
            searchItemPictures.classList.add("row");
            searchItemPictures.id = "searchItemPictures";

            searchContainer.appendChild(searchResultHeader);
            searchContainer.appendChild(searchItemPictures);

            wrapperContainer.appendChild(searchContainer);

            this._searchHolderContainer = wrapperContainer;
            this._isotoperSearchContainer.appendChild(wrapperContainer);
            this.SearchTransitionIn();

            this._searchItemPicture = new ItemPicturesV2(searchItemPictures.id, "", 50, 0, searchItems, false, false, "col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12");
            return;
        }

        if (!this._mainHolder.classList.contains("slideLeftMain")) {
            this.SearchTransitionIn();
        }

        this._searchItemPicture.UpdateItems(searchItems);
    }

    public SearchTransitionIn() {
        window.scroll({ top: 0, behavior: "smooth" });

        this._mainHolder.classList.add("d-none");
        this._mainHolder.classList.add("slideLeftMain");

        this._searchHolderContainer.classList.remove("d-none");
        setTimeout(() => {
            this._searchHolderContainer.classList.add("fade-enter");
        }, 50);
    }

    public SearchTransitionOut() {
        window.scroll({ top: 0, behavior: "smooth" });

        this._mainHolder.classList.remove("d-none");

        this._mainHolder.ontransitionend = (event) => {
            if (event.propertyName === "margin-left") {
                this._searchHolderContainer.classList.remove("fade-enter");
                this._searchHolderContainer.classList.add("d-none");
                this._searchCount = 0;
                this._mainHolder.ontransitionend = null;
            }
        };

        setTimeout(() => {
            this._mainHolder.classList.remove("slideLeftMain");
        }, 50);
    }

    private ReplaceState() {
        let pathLocation = decodeURI(window.location.pathname);
        let historyModel: IHistoryModel = new MylnanHistoryModel();
        historyModel.searchCount = this._searchCount;
        historyModel.historyState = HistoryState.Default;

        this._history.ReplaceState(pathLocation, historyModel);
    }

    private SaveHistory(searchWord: string) {
        let path = window.location.pathname;
        path += '?SearchWord=' + searchWord;

        let historyModel: IHistoryModel = new MylnanHistoryModel();
        historyModel.historyState = HistoryState.Search;
        historyModel.searchWord = searchWord;

        this._searchCount++;
        historyModel.searchCount = this._searchCount;
        this._history.PushState(path, historyModel);
    }

    private GoBack() {
        this._searchCount = this._searchCount === 0 ? 1 : this._searchCount;

        this._history.GoTo(-this._searchCount);
        this._searchCount = 0;
    }

    private UpdateSearchWord(argSearchWord) {
        var searchWord = "";

        if ($("#searchCategory").length > 0 && $("#searchCategory").val() !== '')
            searchWord += $("#searchCategory option:selected").text().trim() + ", ";

        if (argSearchWord !== null && argSearchWord !== '')
            searchWord += argSearchWord + ", ";
        else if ($("#iSearchWord").val() !== "")
            searchWord += $("#iSearchWord").val() + ", ";

        if (searchWord.substr(searchWord.length - 2) === ", ")
            searchWord = searchWord.substr(0, searchWord.length - 2);

        if (searchWord !== "") {
            $("#ResultInformation span#ResultTitle").html(txtShowingResultsFor + ' <i>"' + searchWord + '" </i>');
            $("#ResultInformation").show();
        }
        else {
            $("#ResultInformation").hide();
        }

        $(".SearchSpinner").hide();
    }

    private UpdateResultCount(iTotal) {
        $("#ResultCount").show();
        $("#ResultCount").css('display', 'inline-block');
        if (iTotal === 50)
            $("#ResultCount").html(iTotal + " " + txtItems + ". " + txtTheSearchFindsAMaximumOf50Results + ".");
        else if (iTotal === 1)
            $("#ResultCount").html(iTotal + " " + txtItem);
        else
            $("#ResultCount").html(iTotal + " " + txtItems);
    }

    private SearchFromQuerystring() {
        if (this.getParameterByName("SearchWord") !== "" || this.getParameterByName("CategoryCode") !== "") {
            $("#iSearchWord").val(this.getParameterByName("SearchWord"));
            $("#searchCategory").val(this.getParameterByName("CategoryCode"));
            $(".FrontpageBanner").slideUp('fast');
            //this.SearchExecute();
            this.PerformSearch();
        }
    }

    private getParameterByName(name) {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
            results = regex.exec(location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    }
}