//Add for IE11 support
//import "core-js/stable";

import * as shop from '../Scripts/Shop.js';
import * as error from '../Scripts/Standard/ErrorHandling.js';
import BaseSearchV2 from './Base/BaseSearchV2';
import AjaxCallHelper from './Base/AjaxCallHelper';
import 'noty/src/noty.scss';
import 'noty/src/themes/bootstrap-v4.scss';
import HistoryPublisher from './History/HistoryPublisher';
import { HistoryState } from './History/HistoryInterface';
import baseShop from './Base/BaseShop';

//import "../Css/Mylnan/MylnanFooter.css";

//globalThis.Shop = shop as IShop;
globalThis.ErrorHandling = error as IErrorHandling;

class Main implements IMain {
    private _historyPublisher: HistoryPublisher;

    constructor() {
        this._historyPublisher = new HistoryPublisher();
    }

    public LoadCookieManager() {
        import('./Base/BaseCookieManager').then(module => {
            new module.default();
        })
    }

    public LoadLeftMenuLinks() {
        import('./Mylnan/LeftMenuLinks').then(module => {
            module.default();
        })
    }

    public LoadMacro(categoryCode: string, pageSize: string, ShopCategoryItemId: string) {
        import('./Base/BaseItemPictures').then(async module => {
            let items = await AjaxCallHelper.GetItems(categoryCode, pageSize, '0');

            let macro = new module.default(ShopCategoryItemId, categoryCode,
                Number.parseInt(pageSize), 0, items, false, false,
                "col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 bigger", true);

            this._historyPublisher.eventManager.attach(HistoryState.Default, macro);
        })
    }

    public LoadRelatedItems(itemNos: string, shopCategoryItemId: string) {
        import('./Base/BaseItemPictures').then(async module => {
            let items = await AjaxCallHelper.GetSpecificItems(itemNos);

            await new module.default(shopCategoryItemId, "", 10, 0, items, false, false, "col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12");
        });
    }

    public LoadItemPictureView(categoryCode: string, pageSize: string, displayStart: string, shopCategoryHolderId: string) {
        import('./Mylnan/ItemPictures/ItemPicturesMain').then(async module => {
            let main = await module.ItemPictureMain.Initialize(categoryCode, pageSize, displayStart, shopCategoryHolderId);
            this._historyPublisher.eventManager.attach(HistoryState.LeftMenu, main);
        });
    }

    public LoadCheckoutStep3(dictionary: IDateToTimesMap, deliveryPostCodes: string[]) {
        import('./Checkout/CheckoutStep3').then(module => {
            new module.default(dictionary, deliveryPostCodes);
        });
    }

    public LoadItem() {
        import('./Mylnan/Item/Item').then(module => {
            module.default();
        });
    }

    public LoadUploadFile() {
        import('./Mylnan/Item/FileUpload').then(module => {
            module.default();
        });
    }

    public Initialize() {
        let search = new BaseSearchV2();
        this._historyPublisher.eventManager.attach(HistoryState.LeftMenu, search);
        this._historyPublisher.eventManager.attach(HistoryState.Search, search);
        this._historyPublisher.eventManager.attach(HistoryState.Default, search);
    }

}

//globalThis.BaseShop = shop as IShop;
var tempMain = new Main();
var tempBaseShop = new baseShop();

//$(document).ready(() => {
//    tempMain.Initialize();
//    tempBaseShop.Initialize();
//});

globalThis.BaseShop = tempBaseShop;
globalThis.Main = tempMain;

document.addEventListener('DOMContentLoaded', () => {
    tempMain.Initialize();
    tempBaseShop.Initialize();
}, { once: true });
