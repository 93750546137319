import { WebshopItemCard } from "../Models/webshop-item-card";
import { Webshop_Sales_Line } from "../Models/webshop_-sales_-line";
import { WebshopItemInventory } from "../Models/webshop-item-inventory";

export default class AjaxCallHelper {

    public static async GetItems(input: string, pageSize: string, displayStart: string): Promise<IItemPictureModel> {
        let result: IItemPictureModel;

        try {
            result = await $.ajax({
                type: "POST",
                url: "/Umbraco/Api/JsonApi/ShopCategoryItemsIsotopePictureView" +
                    "?LCID=" + lcid +
                    "&userGuid=" + userGuid +
                    "&customerNo=" + customerNo +
                    "&categoryCode=" + input +
                    "&pageSize=" + pageSize +
                    "&displayStart=" + displayStart,
                contentType: "application/json; charset=utf-8",
                dataType: "json"
            });

        } catch (error) {
            console.log(error);
            globalThis.ErrorHandling.HandleAjaxError('ShopCategoryItemsIsotope', error, error.statusText, error);
        }

        return result;
    }

    public static async GetSpecificItems(itemNos: string): Promise<IItemPictureModel> {
        let result: WebshopItemCard[];

        try {
            result = await $.ajax({
                type: "POST",
                url: "/Umbraco/Api/JsonApi/ShopSpecificItemsIsotope" +
                    "?LCID=" + lcid +
                    "&userGuid=" + userGuid +
                    "&customerNo=" + customerNo +
                    "&itemNos=" + encodeURIComponent(itemNos) +
                    "&pageSize=10" +
                    "&displayStart=0",
                contentType: "application/json; charset=utf-8",
                dataType: "json"
            });
        } catch (error) {
            console.log(error);
            globalThis.ErrorHandling.HandleAjaxError('ShopSpecificItemsIsotope', error, error.statusText, error);
        }

        let output: IItemPictureModel = {
            WebshopItemCards: result,
            TotalItems: result.length
        };

        return output;
    }

    public static async GetSearchItems(argCustomerNo: string, argUserGuid: string, argCategoryCode: string, argSearchWord: string): Promise<IItemPictureModel> {
        console.log('GetSearchItems. argCategoryCode' + argCategoryCode);
        argCategoryCode = argCategoryCode ?? "";
        let result: WebshopItemCard[];

        try {
            result = await $.ajax({
                type: "POST",
                url: "/Umbraco/Api/JsonApi/ShopSearchItemsIsotope" +
                    "?LCID=" + lcid +
                    "&userGuid=" + argUserGuid +
                    "&customerNo=" + argCustomerNo +
                    "&categoryCode=" + encodeURIComponent(argCategoryCode) +
                    "&searchWord=" + encodeURIComponent(argSearchWord),
                contentType: "application/json; charset=utf-8",
                dataType: "json"
            });
        } catch (error) {
            console.log(error);
            globalThis.ErrorHandling.HandleAjaxError('ShopSpecificItemsIsotope', error, error.statusText, error);
        }

        let output: IItemPictureModel = {
            WebshopItemCards: result,
            TotalItems: result.length
        };

        return output;
    }

    public static async SalesLineGetAll(): Promise<Webshop_Sales_Line[]> {
        let result: Webshop_Sales_Line[];

        try {
            result = await $.ajax({
                type: "POST",
                url: "/Umbraco/Api/OrderApi/WebshopSalesLineGetAllLines" +
                    "?userGuid=" + userGuid +
                    "&customerNo=" + customerNo +
                    "&documentType=Quote" +
                    "&sessionID=" + sessionID +
                    "&orderNo=" + orderNo,
                contentType: "application/json; charset=utf-8",
                dataType: "json"
            });
        } catch (error) {
            console.log(error);
            globalThis.ErrorHandling.HandleAjaxError('WebshopSalesLineGetAllLines', error, error.statusText, error);
        }

        return result;
    }

    public static async SalesLineAddItem(itemNo: string, unitOfMeasure: string, variant: string, variantAndItemDescription: string, quantity: number = 1)
        : Promise<Webshop_Sales_Line> {

        let result: Webshop_Sales_Line;

        try {
            result = await $.ajax({
                type: "POST",
                url: "/Umbraco/Api/OrderApi/WebshopSalesLineAddItem" +
                    "?userGuid=" + userGuid +
                    "&customerNo=" + customerNo +
                    "&sessionID=" + sessionID +
                    "&orderNo=" + orderNo +
                    "&itemNo=" + encodeURIComponent(itemNo) +
                    "&quantity=" + quantity +
                    "&unitOfMeasureCode=" + encodeURIComponent(unitOfMeasure) +
                    "&variantCode=" + encodeURIComponent(variant) +
                    "&variantDescription=" + encodeURIComponent(variantAndItemDescription) +
                    "&languageCode=" + languageCode,
                contentType: "application/json; charset=utf-8",
                dataType: "json"
            });
        } catch (error) {
            console.log(error);
            let xhrError = error as JQueryXHR;
            if (xhrError.responseJSON.ExceptionMessage === 'Broytingarfreistin er farin') {
                BaseShop.NotyEndUserError(xhrError.responseJSON.ExceptionMessage);
                return;
            }

            ErrorHandling.HandleAjaxError('WebshopSalesLineAddItem', error, error.statusText, error);
        }

        return result;
    }

    public static async SalesLineRemoveLineNo(lineNo: string): Promise<void> {
        try {
            await $.ajax({
                type: "POST",
                url: "/Umbraco/Api/OrderApi/WebshopSalesLineRemove" +
                    "?userGuid=" + userGuid +
                    "&customerNo=" + customerNo +
                    "&sessionID=" + sessionID +
                    "&orderNo=" + orderNo +
                    "&lineNo=" + lineNo,
                contentType: "application/json; charset=utf-8",
                dataType: "json"
            });
        } catch (error) {
            console.log(error);
            ErrorHandling.HandleAjaxError('WebshopSalesLineRemove', error, error.statusText, error);
        }
    }

    public static async SalesLineChangeUnitPrice(lineNo: string, unitPrice: string): Promise<number> {
        let result: number;

        try {
            result = await $.ajax({
                type: "POST",
                url: "/Umbraco/Api/OrderApi/WebshopSalesLineChangeUnitPrice" +
                    "?userGuid=" + userGuid +
                    "&customerNo=" + customerNo +
                    "&sessionID=" + sessionID +
                    "&orderNo=" + orderNo +
                    "&lineNo=" + lineNo +
                    "&unitPrice=" + unitPrice,
                contentType: "application/json; charset=utf-8",
                dataType: "json"
            });
        } catch (error) {
            console.log(error);
            ErrorHandling.HandleAjaxError('WebshopSalesLineChangeUnitPrice', error, error.statusText, error);
        }

        return result;
    }

    public static async SalesLineGetItemsInventory(itemsOnPage: string): Promise<WebshopItemInventory[]> {
        let result: WebshopItemInventory[];

        try {
            result = await $.ajax({
                type: "GET",
                url: "/Umbraco/Api/JsonApi/GetItemsInventory" +
                    "?itemNos=" + itemsOnPage,
                contentType: "application/json; charset=utf-8",
                dataType: "json"
            });
        } catch (error) {
            console.log(error);
            ErrorHandling.HandleAjaxError('GetItemsInventory', error, error.statusText, error);
        }

        return result;
    }

}
