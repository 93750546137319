import { BrowserHistory, createBrowserHistory } from "history";
import { IHistoryModel } from "./HistoryInterface";

export default class HistorySingleton{
    private static instance: HistorySingleton;
    private _history: BrowserHistory;

    private constructor() {
        this._history = createBrowserHistory();
    }

    public static getInstance() {
        if (!HistorySingleton.instance) {
            HistorySingleton.instance = new HistorySingleton();
        }

        return HistorySingleton.instance;
    }

    public GoBack() {
        this._history.back();
    }

    public GoTo(index: number) {
        this._history.go(index);
    }

    public PushState(path: string, model: IHistoryModel) {
        this._history.push(path, model);
    }

    public ReplaceState(path: string, model: IHistoryModel) {
        this._history.replace(path, model);
    }


}